









































































































import { Component, Vue } from 'vue-property-decorator';
import workspaceModule from '@/store/modules/workspaceModule';
import { getComponent } from '@/utils/helpers';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
	  Dropdown: () => getComponent('common/Dropdown'),
	Multiselect,
  },
})
export default class PersonnelScheduleAddScheduleModal extends Vue {

	roleSelected = []
	roleOptions = [{text: "Automation", value: "Automation"},
	{text: "Engineers", value: "Engineers"},
	{text: "FieldOperator", value: "FieldOperator"},
	{text: "Intervention", value: "Intervention"},
	{text: "Maintenance", value: "Maintenance"},
	{text: "Operators", value: "Operators"},
	{text: "Optimizer", value: "Optimizer"},
	{text: "SafetyCritical", value: "SafetyCritical"},
	{text: "Wireline", value: "Wireline"},
	{text: "Workover", value: "Workover"}]
	dropdownLocation: any[] = []



  async created() {


  }

  get isSavingAddNewSchedule() {
	  return scheduleModule.isSavingAddNewSchedule
  }

  addButtonClicked() {
	  // @ts-ignore
	  this.$emit('add-schedule-button-clicked', this.roleSelected[0].value)
  }

  hideDropdown() {
	  this.dropdownLocation = []
  }

  didSelectDropdownOption() {
	  this.dropdownLocation = []
  }


	didSelectDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]

	}



  closeButtonClicked() {
	  this.$emit("close-add-new-table-popup")
  }


}

